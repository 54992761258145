<template>
    <v-container>
        <v-row dense class="text-right">
            <v-col cols="2">
                <v-switch
                    v-model="chose_grid"
                    :label="`LAYOUT ${grid}`"   
                ></v-switch>
            </v-col>
            <v-col v-show="chose_grid"  cols="4">
                <v-radio-group v-model="oito" row>
                    <v-radio
                        v-for="n in [3,4,6,8]"
                        :key="n"
                        :label="`size ${n}`"
                        :value="n"
                    ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col :cols="oito_doze">
                <v-card class="pa-4 my-2">
                    <MyCardTitle modulo="Paciente" :card-info="currentItem" />
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="4" class="text-end">
                                <v-text-field v-model="geolocalizacao.lat" outlined label="Latitude" float></v-text-field>
                            </v-col>
                            <v-col cols="4" class="text-end">
                                <v-text-field v-model="geolocalizacao.lng" outlined label="Longitude"></v-text-field>
                            </v-col>
                            <v-col cols="1">
                                <v-checkbox v-show="geolocalizacao.id" v-model="geolocalizacao.ativa" label="ativa">
                                </v-checkbox>
                            </v-col>
                            <v-col col="2" class="text-end">
                                <v-btn icon @click="save()" :disabled="(!geolocalizacao.lat && !geolocalizacao.lng)">
                                    <v-icon size="40">mdi-content-save</v-icon>
                                </v-btn>
                                <v-btn v-show="geolocalizacao.id" icon @click="cancelar()">
                                    <v-icon size="40">mdi-cancel</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="pa-4 my-2">
                    {{ geolocalizacoes }}
                    <v-data-table :loading="loading" :items="geolocalizacoes" :headers="headers">
                        <template v-slot:[`item.ativa`]="{ item }" class="text-end">
                            <v-chip class="ma-2" :color="item.ativa ? 'blue' : 'grey'">
                                <span class="white--text">{{ item.ativa ? 'Ativo' : 'Inativo' }}</span>
                            </v-chip>
                        </template>
                        <template v-slot:[`item.edit`]="{ item }">
                            <v-btn @click="edit(item)" icon>
                                <v-icon v-if="item.id">mdi-pencil</v-icon>
                                <v-icon v-else>mdi-plus-circle</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:[`item.map`]="{ item }">
                            <v-btn @click="map_marker(item)" icon>
                                 <v-icon>mdi-map-marker</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="ma-4">
                    <MyMap v-if="refresh" @latlng="latLng" :marcas="marcas" :marca="marca" />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import api from '@/http'
import axios from 'axios'
var key = `key=AIzaSyAfrtV0BQ5k2GUJCgMaTsneZJ7XpmdRZeI`
var link = 'https://maps.googleapis.com/maps/api/geocode/json?'
export default {
    name: 'Geolocalizacao',
    components: {
        MyCardTitle: () => import('../../../uteis/card_title.vue'),
        MyMap: () => import('../../../uteis/myMap.vue')
    },
    props: {
        currentItem: {
            type: Object
        }
    },
    data: () => ({
        loading: false,
        marcas: [],
        marca:{},
        refresh: true,
        chose_grid: true,
        temp_locations: [],
        geolocalizacoes: [],
        geolocalizacao: {},
        oito: 8,
        headers: [
            { text: 'Id', sort: true, value: 'id' },
            { text: 'Lat', sort: true, value: 'lat' },
            { text: 'Lng', sort: true, value: 'lng' },
            { text: 'Ativa', align: 'end', value: 'ativa' },
            { text: 'Edit', align: 'end', value: 'edit' },
            { text: 'Map', align: 'end', value: 'map' },
        ]
    }),
    methods: {
        latLng(event) {
            this.geolocalizacao = event
        },
        map_marker(item){
            this.refresh = false
            this.marca = {lat:item.lat, lng:item.lng, title:`${item.lat} - ${item.lng}`}
            setTimeout(()=>{
                this.refresh = true
            },100)
        },
        get_enderecos() {
            api(`pacientes/enderecos/?enderecos=${this.currentItem.id}`)
                .then((e) => {
                    e.data.forEach(v => {
                        this.get_latlng_apigoogle(v.cep)
                    })
                })
        },
        get_latlng_apigoogle(event) {
            axios.get(`${link}address=${event}&${key}`).then((e) => {
                //const tmp = this.geolocalizacoes
                const laln = e.data.results
                let tmp_ = laln.map((val) => { // latlng da api
                    return {
                        lat:val.geometry.location.lat,
                        lng:val.geometry.location.lng,
                        title:val.formatted_address,
                    }
                })
            
                tmp_.filter(Boolean).forEach(e=>{
                    this.geolocalizacoes.push(e)
                })
                this.marcas = this.geolocalizacoes
            })
        },
        get_geolocalizacao() {
            if (this.currentItem.id !== null) {
                this.loading = true
                api(`pacientes/geolocalizacoes/?paciente=${this.currentItem.id}`)
                    .then((e) => {
                        this.geolocalizacoes = e.data
                        this.marcas = Object.keys(e.data).map((v) => {
                            return {
                                lat: parseFloat(e.data[v].lat),
                                lng: parseFloat(e.data[v].lng),
                                title: `${this.currentItem.nome} ${e.data[v].lat} ${e.data[v].lng}`
                            }
                        })
                    })
                    .finally(() => {
                        this.get_enderecos()
                        this.loading = false
                    })
            }
        },
        cancelar() {
            this.geolocalizacao = {}
        },
        edit(val) {
            this.geolocalizacao = val
        },
        save() {
            this.loading = true
            this.geolocalizacao.paciente = this.currentItem.id
            if (this.geolocalizacao.id === undefined) {
                api
                    .post('pacientes/geolocalizacoes/', this.geolocalizacao)
                    .then(() => {
                        this.get_geolocalizacao()
                    })
            } else {
                api
                    .put(`pacientes/geolocalizacao/${this.geolocalizacao.id}/`, this.geolocalizacao)
                    .then(() => {
                        this.get_geolocalizacao()
                    })
            }
        }
    },
    computed:{
        grid(){ return this.chose_grid?`COLUNA`:`LINHA`},
        oito_doze(){
            return this.chose_grid?this.oito:12
        }
    },
    mounted() {
        this.get_geolocalizacao()
     }
}
</script>